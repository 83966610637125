import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Typography } from '@mui/material';
import { userActions } from '../../redux/actions/user';
import NotificationSetting from '../../screens/AccountProfile/Notifications/NotificationSetting';

const Notifications = () => {
	const dispatch = useDispatch();
	const xSID = useSelector(({ auth }) => auth.xSID, shallowEqual);
	const notifications = useSelector(
		({ user }) => user.notifications,
		shallowEqual
	);

	const updatePreference = (el) => {
		dispatch(
			userActions.updatePreferenceAction({
				xSID,
				data: {
					is_active: el.is_active === 1 ? 0 : 1,
					template_id: el.template_id,
				},
			})
		);
	};

	return (
		<Box>
			<Typography p={1.5} variant='h6'>
				Notifications
			</Typography>
			<Divider />
			{notifications?.length ? (
				notifications?.map((el) => {
					return (
						<NotificationSetting
							key={el.id}
							el={el}
							onChange={updatePreference}
						/>
					);
				})
			) : (
				<Typography variant='body1' p={1.5}>
					No preferences found
				</Typography>
			)}
		</Box>
	);
};

export default Notifications;
