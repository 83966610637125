import { styled, Box, Chip, MenuItem, Switch } from '@mui/material';

export const NotificationsDrawer = styled(Box)(
	({ theme }) => `
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    max-width: 420px;
    box-sizing: border-box !important;

    .stickyHeader {
      display: flex;
      position: sticky;
      padding: 18px;
      top: 0;
      justify-content: space-between;
      z-index: 999;
      border-bottom: 1px solid ${theme.colors.secondary.lighter};
      background-color: ${theme.palette.background.paper};
    }
    .stickyDivider {
      position: sticky;
      top: 55px;
      z-index: 999;
    }
    .stickyTabs {
      position: sticky;
      top: 57px;
      z-index: 999;
      padding-left: 18px;
      padding-right: 18px;
      border-bottom: 1px solid ${theme.colors.secondary.lighter};
      background-color: ${theme.palette.background.paper};
    }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      .stickyHeader {
        align-items: center;
        padding: ${theme.spacing(1)};
      }
      .stickyTabs {
        display: none;
      }
    }
  `
);
export const ChipStyled = styled(Chip)(
	({ theme }) => `
  border-radius: 4px;

  &.success, &.signed {
    color: ${theme.colors.success.dark};
    background-color: ${theme.colors.success.lighter};
  }
  &.pending {
    color: ${theme.colors.warning.dark};
    background-color: ${theme.colors.warning.lighter};
  }
  &.info, &.sent, &.delivered {
    color: ${theme.colors.info.dark};
    background-color: ${theme.colors.info.lighter};
  }
  &.secondary, &.archived {
    color: ${theme.colors.alpha.white[100]};
    background-color: ${theme.colors.alpha.black[100]};
  }
  &.main {
    color: ${theme.colors.primary.main};
    background-color: ${theme.colors.primary.lighter};
  }
`
);
export const TabLabel = styled(Box)(
	() => `
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  `
);
export const NotBox = styled(Box)(
	() => `
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 18px 18px 14px;
    flex-flow: row nowrap;
    align-items: flex-start;

    > .MuiAvatar-root {
      margin-right: 18px;
    }
    > .MuiBox-root {
      width: 100%;
    }
  `
);
export const DotLegend = styled('span')(
	({ theme }) => `
      border-radius: 22px;
      width: 10px;
      height: 10px;
      display: inline-block;
      position: absolute;
      top: 4px;
      right: 0;
      background: ${theme.colors.info.main};
  `
);
export const MobileMenuItem = styled(MenuItem)(
	({ theme }) => `
  display: flex;
  border-radius: 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.secondary.lighter};

  &:last-of-type {
    border-bottom: none;
  }
`
);
export const IOSSwitch = styled((props) => (
	<Switch
		focusVisibleClassName='.Mui-focusVisible'
		disableRipple
		{...props}
	/>
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.colors.success.main,
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
			'.MuiSwitch-thumb': {
				// todo : apply dark mode
				backgroundColor: '#fff',
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			// color: '#33cf4d',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22,
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));
