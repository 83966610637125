import React, { useContext, useRef, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import {
	Avatar,
	Box,
	Button,
	Divider,
	ListItemText,
	Popover,
	Typography,
} from '@mui/material';
// import { Link } from 'react-router-dom';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
// import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
// import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { ThemeContext } from '../../../theme/MuiThemeProvider';
import { authActions } from '../../../redux/actions/auth';
import { uiActions } from '../../../redux/actions/ui';
import Link from '../../Link';
import {
	UserBoxButton,
	UserBoxText,
	UserBoxLabel,
	UserBoxDescription,
	MenuUserBox,
	MenuNavBox,
	ToggleThemeBox,
} from '../styles';
import { getBasicAmount } from '../../../utils/amount';

const HeaderUserbox = ({ dispatch }) => {
	const userInfoDefault = {
		photo: 'broken.jpg',
	};
	const userInfo = useSelector(({ user }) => user.userInfo, shallowEqual);
	const { appTheme, profileOpen } = useSelector(({ ui }) => ui, shallowEqual);
	const balance = useSelector(
		({ dashboard }) => dashboard.balance,
		shallowEqual
	);
	const xSID = useSelector(({ auth }) => auth.xSID, shallowEqual);

	const setThemeName = useContext(ThemeContext);
	const curThemeName = appTheme || 'GreyGooseTheme';
	const ref = useRef(null);
	const [theme, setTheme] = useState(curThemeName);

	const handleOpen = () => {
		dispatch(uiActions.toggleProfileAction(true));
	};
	const handleClose = () => {
		dispatch(uiActions.toggleProfileAction(false));
	};
	const handleLogout = async () => {
		handleClose();
		dispatch(authActions.logoutAction({ xSID }));
	};
	const changeTheme = () => {
		const themeColor =
			curThemeName === 'GreyGooseTheme'
				? 'NebulaFighterTheme'
				: 'GreyGooseTheme';
		setTheme(themeColor);
		setThemeName(themeColor);
		dispatch(uiActions.setThemeAction(themeColor));
		dispatch(uiActions.toggleProfileAction(false));
	};
	// const openNotificationDrawer = () => {
	// 	handleClose();
	// 	dispatch(uiActions.toggleNotificationsAction(true));
	// };

	return (
		<>
			<UserBoxButton color='secondary' ref={ref} onClick={handleOpen}>
				<Box
					sx={{
						display: { xl: 'none', md: 'block', xs: 'none' },
						mr: 2,
					}}
				>
					<UserBoxText>
						<UserBoxLabel variant='body1'>
							{userInfo?.username}
						</UserBoxLabel>
						{/* <UserBoxDescription variant='body2'>
							Available balance: ${balance}
						</UserBoxDescription> */}
					</UserBoxText>
				</Box>
				<Avatar
					variant='rounded'
					alt={userInfo?.email}
					src={userInfo?.photo || userInfoDefault.photo}
				/>
				<Box sx={{ display: { xl: 'none', sm: 'block', xs: 'none' } }}>
					<ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
				</Box>
			</UserBoxButton>
			<Popover
				anchorEl={ref.current}
				onClose={handleClose}
				open={profileOpen}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{ zIndex: 9999 }}
			>
				<MenuUserBox>
					<Avatar
						variant='rounded'
						alt={userInfo?.name}
						src={userInfo?.photo || userInfoDefault.photo}
					/>
					<UserBoxText>
						<UserBoxLabel variant='body1'>
							{userInfo?.username}
						</UserBoxLabel>
						<UserBoxDescription variant='body2'>
							{userInfo?.email}
						</UserBoxDescription>
					</UserBoxText>
				</MenuUserBox>
				<Divider sx={{ mb: 0 }} />
				<MenuNavBox>
					<Box className='_link' sx={{ padding: '4px 8px' }}>
						<Link
							cs={{ textDecoration: 'none', fontWeight: 500 }}
							to={'/user_profile'}
							text={
								<Box display='flex'>
									<AccountBoxTwoToneIcon fontSize='small' />
									<ListItemText
										sx={{ marginLeft: '10px' }}
										primary='Profile'
									/>
								</Box>
							}
						/>
					</Box>
					<Box
						sx={{
							padding: '4px 8px',
							display: { md: 'none', xs: 'block' },
						}}
					>
						<ToggleThemeBox display='flex' onClick={changeTheme}>
							{theme === 'GreyGooseTheme' ? (
								<DarkModeIcon fontSize='small' />
							) : (
								<LightModeIcon fontSize='small' />
							)}
							<ListItemText
								sx={{ marginLeft: '10px' }}
								primary='Toggle theme'
							/>
						</ToggleThemeBox>
					</Box>
				</MenuNavBox>
				<Divider />
				<Box
					sx={{
						p: 1,
						display: 'flex',
						width: '100%',
						flexFlow: 'column nowrap',
						boxSizing: 'border-box',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							width: '100%',
							flexFlow: 'row nowrap',
							alignItems: 'center',
						}}
					>
						<MonetizationOnTwoToneIcon
							fontSize='large'
							color='warning'
							sx={{ mr: 1 }}
						/>
						<Typography variant='h3'>
							{getBasicAmount(balance, 'USD')}
						</Typography>
					</Box>
					<Typography variant='h6' pl={5}>
						Wallet balance
					</Typography>
				</Box>
				<Divider />
				<Box sx={{ m: 1 }}>
					<Button color='primary' fullWidth onClick={handleLogout}>
						<LockOpenTwoToneIcon sx={{ mr: 1 }} />
						Sign out
					</Button>
				</Box>
			</Popover>
		</>
	);
};

export default HeaderUserbox;
