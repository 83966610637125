import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import LogoImg from '../../theme/assets/logo.png';
import LogoFullImg from '../../theme/assets/eshkol_logo.png';
import LogoFullWhiteImg from '../../theme/assets/eshkol_logo_white.png';
import { LogoBox } from './styles';

const Logo = ({ cs, full }) => {
	const appTheme = useSelector(({ ui }) => ui.appTheme, shallowEqual);
	const FullLogo =
		appTheme === 'GreyGooseTheme' ? LogoFullImg : LogoFullWhiteImg;

	return (
		<LogoBox className='logo' sx={cs}>
			{full ? (
				<img src={FullLogo} className='full' alt='eshkol' />
			) : (
				<img src={LogoImg} alt='eshkol' />
			)}
		</LogoBox>
	);
};

export default Logo;
