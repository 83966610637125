import React from 'react';
import { Box } from '@mui/material';
import HeaderSettings from './HeaderSettings';

const HeaderButtons = () => {
	return (
		<Box sx={{ display: { sm: 'block', sx: 'none' } }}>
			<HeaderSettings />
		</Box>
	);
};

export default HeaderButtons;
