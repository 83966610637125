import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const NotRequireAuth = ({ children }) => {
	const isAuth = useSelector(({ auth }) => auth.isAuth, shallowEqual);
	const location = useLocation();

	if (isAuth) {
		const { path, search } = location.state || {};
		const redirectPath = path ? path + search : '/dashboard';

		return <Navigate to={redirectPath} replace />;
	}

	return children;
};

export default NotRequireAuth;
