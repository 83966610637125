import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }) => {
	const isAuth = useSelector(({ auth }) => auth.isAuth, shallowEqual);
	const location = useLocation();

	return isAuth === true ? (
		children
	) : (
		<Navigate to='/signin' replace state={{ path: location.pathname, search: location.search }} />
	);
};

export default RequireAuth;
